<template>
    <div class="content">
        <div class="tool">
            <el-button type="primary" class="el-icon-plus marg" @click="AddClick"> 新增</el-button>
            <el-input placeholder="请输入车场名称" v-model="searchForm.name" class="marg" style="width:150px"></el-input>
            <div v-if='User.user_type===0' class="tool marg">
                <label class="fn marg1">服务商</label>
                <el-select v-model="searchForm.ser_id" placeholder="请选择服务商">
                    <el-option label="无" :value="0"></el-option>
                    <el-option v-for="item in serDataList" :key="item.sp_id" :label="item.sp_name" :value="item.sp_id"></el-option>
                </el-select>
            </div>
            <div v-if='User.user_type===0||User.user_type===1||User.user_type===3' class="tool marg">
                <label class="fn marg1">运营商</label>
                <el-select v-model="searchForm.union_id" placeholder="请选择运营商">
                    <el-option label="无" :value="0"></el-option>
                    <el-option v-for="item in unionDataList" :key="item.up_id" :label="item.up_name" :value="item.up_id"></el-option>
                </el-select>
            </div>
            <el-button type="primary" class="el-icon-search marg" @click="FindClick"> 查询</el-button>
        </div>

        <el-table :data='DataList' border stripe :highlight-current-row='true' max-height='calc(100vh - 180px)' height='calc(100vh - 180px)'>
           <el-table-column align="center" prop="pk_cloud_id" label="车场ID"></el-table-column>
            <el-table-column align="center" prop="pk_name" label="名称"></el-table-column>
            <el-table-column align="center" prop="union_name" label="运营商"></el-table-column>
            <el-table-column align="center" prop="sericer_name" label="服务商"></el-table-column>
             <el-table-column align="center" prop="pk_phone" label="联系方式"></el-table-column>
            <el-table-column align="center" label="心跳时间">
              <template slot-scope="scope">
                {{FormData(scope.row.pk_heat_time)}}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="pk_cloud_ckey" label="车场秘钥"></el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <div class="link_btn">
                        <el-link type="primary" @click="Edit(scope.row)">编辑</el-link>
                        <el-link type="warning" @click="EditUser(scope.row)">操作员</el-link>
                        <el-link type="primary" @click="CloudConfig(scope.row)">平台配置</el-link>
                        <el-link type="danger" @click="Restart(scope.row)">重启服务</el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="fy" layout="prev, pager, next" @current-change="current_change" :total="searchForm.total" background :page-size="searchForm.pageSize"></el-pagination>

        <el-dialog :title="title" :visible.sync="dialogVisible" width='50%' :append-to-body='true'>
            <el-form :model="parkForm" :rules="rules" ref="parkForm" label-width="100px" size='medium'>
                <el-form-item label="车场名称" prop="pk_name">
                    <el-input v-model="parkForm.pk_name"></el-input>
                </el-form-item>
                <el-form-item v-if='User.user_type===0' label="所属服务商">
                    <el-select v-model="parkForm.pk_sp_id" style="width:100%;">
                        <el-option label="无" :value="0"></el-option>
                        <el-option v-for="item in serDataList" :key="item.sp_id" :label="item.sp_name" :value="item.sp_id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if='User.user_type===0||User.user_type===1||User.user_type===3'  label="所属运营商">
                    <el-select v-model="parkForm.pk_up_id" style="width:100%;">
                        <el-option label="无" :value="0"></el-option>
                        <el-option v-for="item in unionDataList" :key="item.up_id" :label="item.up_name" :value="item.up_id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="联系方式">
                    <el-input v-model="parkForm.pk_phone"></el-input>
                </el-form-item>
            </el-form>
            <baidu-map ak='GnVkZbGd47aleyQGoGScrNDTixMiT1Ui' v-if="dialogVisible" :center="parkForm.pk_lng!==0&&parkForm.pk_lng!==null?{lng: parkForm.pk_lng, lat: parkForm.pk_lat}:defaultCity" :zoom="11" :scroll-wheel-zoom="true" class="bm-view" style="height:300px" @click="MapClick">
                 <bm-marker v-if="parkForm.pk_lng!==0&&parkForm.pk_lng!==null" :position="{lng: parkForm.pk_lng, lat: parkForm.pk_lat}" >
                 </bm-marker>
            </baidu-map>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog v-if="userDialogVisible" :title="title" :visible.sync="userDialogVisible" width='50%' :append-to-body='true'>
            <SerUser :userType="5" :beglongId="parkId"/>
            <span slot="footer" class="dialog-footer">
                <el-button @click="userDialogVisible = false">关 闭</el-button>
            </span>
        </el-dialog>

        <el-dialog v-if="cloudDialogVisible" :title="title" :visible.sync="cloudDialogVisible" width='40%' :append-to-body='true'>
            <el-form :model="parkForm" ref="parkForm" label-width="100px" size='medium'>
              <el-card>
                <div style="width:100%">
                  <span style="float:left;color:#36A4FF">支付平台</span>
                </div>
                <div style="margin-top:35px">
                  <el-form-item label="支付方式">
                    <el-select  v-model="parkForm.pk_pay_type" style="width:100%;">
                      <el-option label="PP支付(SDK)" :value="0"></el-option>
                      <el-option label="乐刷" :value="1"></el-option>
                      <el-option label="PP聚合支付" :value="2"></el-option>
                      <el-option label="泊链" :value="3"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="车场编号">
                      <el-input v-model="parkForm.pk_pay_id"></el-input>
                  </el-form-item>
                  <el-form-item label="车场秘钥">
                      <el-input v-model="parkForm.pk_pay_ckey"></el-input>
                  </el-form-item>
                  <el-form-item label="服务器地址" v-if="parkForm.pk_pay_type===null||parkForm.pk_pay_type===0">
                      <el-input v-model="parkForm.pk_pay_server"></el-input>
                  </el-form-item>
                </div>
              </el-card>
              <el-card>
                <div style="width:100%">
                  <span style="float:left;color:#36A4FF">ETC</span>
                </div>
                <div style="margin-top:35px">
                  <el-form-item label="车场 appid">
                      <el-input v-model="parkForm.pk_etc_appid"></el-input>
                  </el-form-item>
                </div>
              </el-card>
              <el-card>
                 <div style="width:100%">
                  <span style="float:left;color:#36A4FF">远程设置</span>
                </div>
                <div style="margin-top:35px">
                  <el-form-item label="识别码">
                      <el-input v-model="parkForm.pk_oray_id"></el-input>
                  </el-form-item>
                  <el-form-item label="验证码">
                      <el-input v-model="parkForm.pk_oray_pwd"></el-input>
                  </el-form-item>
                </div>
              </el-card>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cloudDialogVisible = false">关 闭</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import util from '../../util/util'
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmMarker from 'vue-baidu-map/components/overlays/Marker.vue'
import SerUser from '../../components/servicer/SerUser.vue'

export default {
  data: function () {
    return {
      searchForm: {
        name: '',
        ser_id: 0,
        union_id: 0,
        pageSize: 15,
        pageNo: 1,
        total: 0
      },
      rules: {
        pk_name: [{ required: true, message: '请输入车场名称', trigger: 'blur' }]
      },
      User: null,
      DataList: [],
      serDataList: [],
      unionDataList: [],

      cityDataList:[],
      defaultCity:'北京',

      isReturn:true,

      title: '',
      dialogVisible: false,
      userDialogVisible: false,
      parkForm: {
        pk_id: 0,
        pk_name: '',
        pk_phone: '',
        pk_lng: 0,
        pk_lat: 0,
        pk_sp_id: 0,
        pk_up_id: 0,

        pk_pay_type:0,
        pk_pay_id: '',
        pk_pay_ckey: '',
        pk_pay_server: '',
        pk_oray_id: '',
        pk_oray_pwd: '',
        pk_etc_appid:''
      },
      parkId: 0,
      cloudDialogVisible: false
    }
  },
  components: {
    BaiduMap,
    BmMarker,
    SerUser
  },
  computed:{
    FormData () {
        return function (time) {
          if(time)
            return util.FormateDate(time, 2)
          else
            return ''
        }
    },
  },
  created () {
    this.GetDataList()
    this.User = util.JesToken()
  },
  methods: {
    GetDataList () {
      util.Get('parkmanage/getparkdatalist', this.searchForm).then(res => {
        if (res.rpStatus === 10000) {
            this.DataList = res.list[2]
            this.serDataList = res.list[0]
            this.unionDataList = res.list[1]
            this.searchForm.total = res.total
        }
      })
    },
    FindClick () {
      this.GetDataList()
    },
    MapClick (tp, target) {
      this.parkForm.pk_lng = tp.point.lng
      this.parkForm.pk_lat = tp.point.lat
    },
    current_change (page) {
      this.searchForm.pageNo = page
      this.GetDataList()
    },
    AddClick () {
      this.dialogVisible = true
      this.title = '新增'
      this.parkForm.pk_id = 0
      this.parkForm.pk_name = ''
      this.parkForm.pk_phone = ''
      this.parkForm.pk_lng = 0
      this.parkForm.pk_lat = 0
      this.parkForm.pk_sp_id = 0
      this.parkForm.pk_up_id = 0
      this.parkForm.pk_cloud_id = 0
      this.parkForm.pk_pay_type=0
      this.parkForm.pk_pay_id = ''
      this.parkForm.pk_pay_ckey = ''
      this.parkForm.pk_pay_server = ''
      this.parkForm.pk_oray_id = ''
      this.parkForm.pk_oray_pwd = ''
      this.parkForm.pk_etc_appid=''
    },
    Edit (row) {
      this.parkForm = JSON.parse(JSON.stringify(row))
      this.dialogVisible = true
      this.title = '修改'
    },
    submit () {
      if(this.isReturn){
        this.$refs.parkForm.validate((valid) => {
          if (valid) {
            this.isReturn=false
            util.Post('parkmanage/edit', this.parkForm).then(res => {
              if (res.rpStatus === 10000) {
                this.GetDataList()
                this.dialogVisible = false
                this.cloudDialogVisible = false
                this.$message.success('保存成功')
              } else { this.$message.error(res.rpMsg)}
              this.isReturn=true
            })
          }
        })
      }
      else{
        this.$message({message:'请稍后，正在处理中...',type: 'warning'})
      }
    },
    EditUser (row) {
      this.userDialogVisible = true
      this.title = '操作员'
      this.parkId = row.pk_id
    },
    CloudConfig (row) {
      this.cloudDialogVisible = true
      this.title = '平台配置'
      console.log(row)
      this.parkForm = JSON.parse(JSON.stringify(row))
    },
    Restart(row){
      this.parkForm = JSON.parse(JSON.stringify(row))
      util.Post('parkmanage/restart', this.parkForm).then(res => {
            if (res.rpStatus === 10000) {
              this.$message.success('重启车场服务成功') 
            } else { this.$message.error(res.rpMsg) }
      })
    }
  }
}
</script>

<style scoped>
.tool{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.marg{
    margin-right: 20px;
}
.marg1{
    margin-right: 10px;
}
.el-table{
    margin-top: 15px;
}
.fn{
    font-size: 13px;
}
.el-link{
    margin-right: 10px;
    font-size: 11px;
}
.card{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
::v-deep .el-dialog{
  --el-bg-color:#000741 !important;
}
::v-deep .el-dialog__header{
  --el-text-color-primary:#1effff;
  --el-text-color-regular:#fff;
  padding: 0px !important;
  background-color: #309CE2;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-dialog__title{
    color: white;
}

::v-deep .el-dialog__headerbtn .el-dialog__close{
    color: white;
} 
</style>
